import React from 'react';
import Slider from 'react-slick';
import LorryLogoImage from '../../images/tv2-lorry-logo.png';
import LorryLogo2Image from '../../images/tv2-lorry-logo-2.png';
import './LorryNews.css';
import {connect} from "react-redux";

const LorryNews = props => {

    const renderSlide = (n) => {

/*        const imgUriContainer = articleImg && articleImg['DR:ImageUri620x349'];
        const articleImgUrl = imgUriContainer && imgUriContainer['#cdata-section'];
        */
        return (
            <div key={n.guid||n.guid['#text']||n.guid['#cdata-section']} className="news-slide-lorry clearfix">
                <h3>{replaceQuotes(n.title['#cdata-section'])}</h3>
                <div className='clearfix'/>
                <img className='article-img' src={(n.enclosure&& n.enclosure['@url']) || LorryLogo2Image} alt='' />
                <div className="bodytext">{!(n.description) || n.description['#cdata-section']}</div>                    
                <img src={LorryLogoImage} className='logo' alt='' />
            </div>
        );
    };

    const slideComponents = props.state.lorryNewsState.news.map((n) => renderSlide(n));

    function replaceQuotes (str) {
        var reg1= /(&quot;)|(&#039;)/ig;
        //var reg2= new RegExp("&#039;","ig");

        var resStr = str.replace(reg1,"'");
        //resStr = resStr.replace(reg2,"'");

        return resStr;
    };
    const settings = {
        autoplay : true,
        swipeToSlide: true,
        speed: 300,
        autoplaySpeed: 10000,
        arrows: false,
    };

    return(
        <div className="temp-slide">
            <Slider {...settings}>
               {slideComponents}
            </Slider>
        </div>
    );

};

export default connect(
    state => ({state: state})
)(LorryNews);
import React from 'react';
import { connect } from 'react-redux';


const createSingleFreeTextView = (freeText) => {
    return(
    <div key={freeText.id} className='free-text-item'>
        <div className='free-text'>
            <div className='text' style={{fontSize: `${freeText.size}vh`}}>
                {freeText.content}
            </div>
        </div>
    </div>
    )
}


const FreeText = (props) => {
    if(props.text != null){
        return props.text.map(freeText => createSingleFreeTextView(freeText));
    }
    return null;
}

export default connect(
    (state) => ({text: state.freeTextState.text})
)(FreeText);
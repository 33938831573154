import React from 'react';
import Team from './Team'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import connect from 'react-redux/es/connect/connect';
import * as ResourceStore from '../../store/ResourceStore';
import moment from 'moment';

const CustomTeamList = props => {
    const units = props.state[ResourceStore.reducerName].units;
    const bookings = props.state[ResourceStore.reducerName].bookings;
    const now = moment();
    const currentBookings = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const teams = props.state.teamState.teams;
    if(teams.length < 1) return null;

    const addUnits = team => {
        team.units = currentBookings.reduce((result,b) => {
            if(b.teamId === team.id){
                result.push(units.find(u => u.id === b.unitId));
            }
            return result;
        }, []);
    };

    const administration = teams.find(x => x.id === 21);
    addUnits(administration);

    // const pladsen = teams.find(x => x.id === 25);
    // addUnits(pladsen);

    const vejomraadet = teams.find(x => x.id === 22);
    addUnits(vejomraadet);
    const vej = teams.find(x => x.id === 119);
    addUnits(vej);

    const gronvej = teams.find(x => x.id === 26);
    addUnits(gronvej);

    const komunaleEjendome = teams.find(x => x.id === 23);
    addUnits(komunaleEjendome);

    const team2 = teams.find(x => x.id === 28);
    addUnits(team2);

    const team3 = teams.find(x => x.id === 29);
    addUnits(team3);

    const tvear = teams.find(x => x.id === 30);
    addUnits(tvear);

    const vearkstedOgGenbrug = teams.find(x => x.id === 24);
    addUnits(vearkstedOgGenbrug);

    const genbrug = teams.find(x => x.id === 31);
    addUnits(genbrug);

    return (
        <div className="team-list">
            {administration &&
                <Team
                    team={administration}
                    width={11.5}
                />
            }
            {vejomraadet &&
                <Team team={vejomraadet} width={21}
                // subTeams={[vej,pladsen]}/>
                subTeams={[vej]}/>
                
            }
            {gronvej && 
                <Team team={gronvej} width={12.5}/>
            }
            {/* {komunaleEjendome &&
                <Team team={komunaleEjendome} width={25} subTeams={[team2, team3, tvear]}/>
            } */}
                        {komunaleEjendome &&
                <Team team={komunaleEjendome} width={35} subTeams={[team2, team3]}/>
            }

            {vearkstedOgGenbrug &&
                <Team team={vearkstedOgGenbrug} width={20} subTeams={[genbrug]}/>
            }
            <ColorLegend />
        </div>
    );
};

export default connect(
    state => ({state: state})
)(CustomTeamList);
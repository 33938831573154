import React from 'react';
import Menu from '../Menu';
import connect from 'react-redux/es/connect/connect';
import config from '../../config.json';
import {bindActionCreators} from 'redux';
import * as AllocatedUnitStore from '../../store/AllocatedUnitStore';
import image from '../../images/flag.png';


import './AllocatedUnit.css';
import '../organization/Person.css';


const AllocatedUnit = props => {






    const allocatedUnits = props.allocatedUnitState.allocatedUnits;

    const style = {border: 'none'}; //{ borderColor: 'black', borderRadius: '50%', borderStyle: 'solid'};


    const createPersonView = (unitId, person) => {
        return (<>
            <div key={`${unitId}-${person.name}`} className="person clearfix circle" style={{marginBottom: '1.1vw'}}>
                <div className="profile clearfix" >
                    <img className="profile-img" src={config.server + person.imagePath} alt="" style={style ? style : {}}/>
                </div>
                <div className="flytext">{person.name}</div>
                <div className="flytext-title">{person.title}</div>
                <div className="flytext-phone">{person.contactPhoneNumber}</div>
            </div>
        </>)
    }

    return(
        <div className="App">
            <Menu />
            <div className='AllocatedUnit'>
                <div className='padding-content'>
                    <div className='units-container'>
                        <h2>Biler</h2>
                        <div  className='units'>
                            {
                                allocatedUnits.map((allocatedUnit,i) => {
                                    return(<>
                                        <div key={`allocated-unit-${allocatedUnit.id}`} className={"unit"}>
                                            <div className='allocated-unit-info'>
                                                <p className='allocated-unit-name-small'>{allocatedUnit.name}</p>
                                                <p className='allocated-unit-name-small'>{allocatedUnit.internalId} - {allocatedUnit.registrationNo}</p>
                                                {allocatedUnit.allocatedUnitOwners && 
                                                    allocatedUnit.allocatedUnitOwners.map(owner => createPersonView(allocatedUnit.id, owner))
                                                }
                                            </div>
                                        </div>
                                    </>
                                    )
                                })
                            }
                        </div>
                        <div className='clearfix'/>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default connect(
    state => ({allocatedUnitState: state[AllocatedUnitStore.reducerName]}),
    dispatch => ({
        allocatedUnitActions: bindActionCreators(AllocatedUnitStore.actionCreators, dispatch),
    })
)(AllocatedUnit);